










































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTopic from '@improve/common-components/src/components/topic/BaseTopic.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import { BaseTopicCB } from '@improve/common-utils/src/types/BaseTopicCB';
import Topic from '@improve/common-utils/src/model/Topic';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';

@Component({
  name: 'EditTopic',
  components: {
    TabContent,
    BaseTopic,
    BaseButton
  }
})
export default class EditTopic extends Vue {
  @Getter organizationTopicsById!: (id: string) => Topic;

  @Action fetchTopicsByContext!: ActionMethod;

  @Action updateTopic!: ActionMethod;

  @Action addUserSubscription!: ActionMethod;

  @Action removeUserSubscription!: ActionMethod;

  get topic(): Topic {
    const topicId = this.$route.params.id;
    return this.organizationTopicsById(topicId);
  }

  get baseTopicIns(): BaseTopic {
    return this.$refs.baseTopic as any;
  }

  async created(): Promise<void> {
    await this.fetchTopic();
  }

  async fetchTopic(): Promise<void> {
    const topicId = this.$route.params.id;
    if (!this.organizationTopicsById(topicId)) {
      await this.fetchTopicsByContext();
    }
  }

  async saveTopic(): Promise<void> {
    if (!(await this.validate())) {
      return;
    }
    const topicPayload: BaseTopicCB = this.baseTopicIns.prepareTopic();
    await this.updateTopic(topicPayload.topic);
    await this.addUserSubscription({
      users: topicPayload.addExperts,
      topic: topicPayload.topic
    });
    await this.removeUserSubscription({
      users: topicPayload.removeExperts,
      topic: topicPayload.topic
    });
    this.$router.push({ name: 'Topics' });
  }

  validate(): Promise<any> {
    return this.baseTopicIns.validate();
  }

  cancel(): void {
    this.$router.push({ name: 'Topics' });
  }
}
